<template>
	<div class="modal" style="display:block">
        <div class="modal-content">

            <template v-if="step == 1">
                <div class="indice">
                    <p>Une tablette…</p>
                    <img src="img/tablette-insitu.jpg" alt="Tablette">
                </div>
            </template>

            <template v-if="step == 2">
                <div class="tablette">
                    <span class="number">1</span>
                    <span class="link" @click="goNext()"></span>
                </div>
            </template>

            <template v-if="step == 3">
                <img src="img/tablette-rappel.jpg" alt="Rappel">
            </template>

            <template v-if="step == 4">
                <div class="tablette">
                    <span class="link link-agenda" @click="goNext()"></span>
                </div>
            </template>

            <template v-if="step == 5">
                <div class="indice">
                    <p>Nicolas semble être très occupé</p>
                    <img class="big" src="img/tablette-agenda.jpg" alt="Agenda">
                </div>
            </template>

            <template v-if="step == 6">
                <div class="tablette">
                    <span class="link link-medilib" @click="goNext()"></span>
                </div>
            </template>

            <template v-if="step == 7">
                <div class="medilib">
                    <h2>Bienvenue <br>sur Medilib !</h2>
                    <p>Vous voulez :</p>
                    <button type="button" class="btn align-center" @click="goNext()">Prendre un RDV</button>
                </div>
            </template>

            <template v-if="step == 8">
                <div class="medilib rdv">
                    <div class="cadre"></div>
                    <p>Prochaines disponibilités pour votre rendez-vous : </p>
                    <table cellspacing="10">
                        <tr>
                            <th>Février</th>
                            <th>Mars</th>
                            <th>Avril</th>
                        </tr>
                        <tr>
                            <td>
                                <button disabled class="btn">Vendredi 27 Février</button>
                            </td>
                            <td>
                                <button disabled class="btn">Mardi 3 Mars</button>
                                <button type="button" class="btn" @click="goNext()">Mercredi 4 Mars</button>
                                <button disabled class="btn">Jeudi 5 Mars</button>
                            </td>
                            <td>
                                <button disabled class="btn">Mercredi 21 Avril</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </template>

            <template v-if="step == 9">
                <img src="img/tablette-agenda-rdv.jpg" alt="Agenda rdv pris">
            </template>

        </div>

        <div class="modal-footer">
            <button v-if="[1,3,5].includes(step)" type="button" class="btn btn-blue align-right" @click="goNext()"><span>Suivant</span></button>

            <button v-if="step == 9" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter au dossier médical</span></button>
        </div>
    </div>
</template>


<script>
// import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'Tablette',
    data() {
        return {
            step : 1,
        }
    },
    computed : {
    },
    methods : {
        close() {
          this.$emit('close')
        },
        goNext(){
            this.step++;
        },

		addToDossier() {
			this.$store.commit("addToDossier", {dossier: 'medical', item: 'rdv-rhumato-medilib'})
			this.$emit('close')
		},
    }
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    img {
        max-width: 100%;
    }

    .modal-content {
        padding: 2rem;
    }

    .indice {
        .big {
            max-height: 53rem;
        }
    }

    .tablette {
        background: url('/img/tablette-bg.jpg') no-repeat center;
        background-size: contain;
        height: 100%;
        position: relative;

        .link {
            cursor: pointer;
            position: absolute;
            height: 16rem;
            width: 12rem;
            top: 19.5rem;
            left: 22.5rem;

            &-agenda {
                left: 44rem;
            }

            &-medilib {
                left: 64rem;
            }
        }

        .number {
            background: red;
            color: white;
            border-radius: 50%;
            line-height: 3rem;
            height: 3rem;
            width: 3rem;
            position: absolute;
            top: 31rem;
            left: 31rem;
        }
    }

    .medilib {
        background: url('/img/tablette-medilib.png') no-repeat center;
        background-size: contain;
        height: calc(100% + 4rem);
        width: calc(100% + 4rem);
        padding-top: 19rem;
        position: relative;
        top: -2rem;
        left: -2rem;

        &.rdv {
            padding-top: 16rem;
        }

        .cadre {
            border: 1rem solid $green;
            height: 49rem;
            width: 97.1rem;
            position: absolute;
            top: 11.1rem;
            left: 2.5rem;
        }

        h2 {
            color: white;
            font-size: 8.5rem;
            font-weight: 400;
            line-height: 1;
            margin: 0 0 2rem;
        }

        p {
            color: $navy;
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 2rem;
        }

        .btn {
            background: $navy;
            border-radius: 0;
            height: 8rem;

            &:hover {
                background: $blue;
            }
        }

        table {
            color: black;
            margin: 0 auto;
            width: 90%;

            th {
                background: white;
                font-size: 2rem;
                font-weight: 500;
                height: 6rem;
                text-transform: uppercase;
                width: 33.33%;
            }

            td {
                vertical-align: top;
            }

            .btn {
                background: $green;
                font-size: 2rem;
                font-weight: 500;
                height: 6rem;
                margin-bottom: 1rem;
                padding: 0 2rem;
                text-transform: uppercase;
                width: 100%;

                &:disabled {
                    background: #707070;
                    color: black;
                    cursor: default;
                }
            }
        }
    }

</style>